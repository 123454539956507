import React from 'react';
import './CalendarioMINTC.css';

class CalendarioMINTC extends React.PureComponent {
    state = {
        isLoading: false,
        data: null,
        error: null
    }

    fetchData(feed) {
        this.setState({ isLoading: true })

        fetch(feed)
        
            .then(response => response.json())

            .then(data => 
                this.setState({
                    data: data,
                    isLoading: false,
                })
            )

            .catch(error => this.setState({ error, isLoading: false }));
    }

    componentDidMount() {
        this.fetchData(this.props.feed + '?v=' + new Date().getTime()); 
    }

    render() {

        if(!this.state.data) {
            return null;
        }

        const {citta, medici} = this.state.data;

        return (
            <div id="calendario-mintc">
        
                <p className="calendario-mintc__title TextCaptionMedium-32">Consulta il calendario e prenota un appuntamento a {citta}</p>
                
                <div className="calendario-mintc__hero">
                    <p className="calendario-mintc__hero-message">Seleziona il medico e la sede di tuo interesse e prenota un appuntamento</p>
                </div>

                <div className="calendario-mintc__medici">

                    {
                        medici.map((medico, index) => 
                        
                            <div className="float-col-33" key={index}>

                                <div className="calendario-mintc__medico">
                                    <div className="calendario-mintc__medico-head calendario-mintc_prl8 TextSmall-18">
                                        <div>
                                            <strong><a href={medico.urlPaginaMedico}>{medico.titolo} {medico.nome} {medico.cognome}</a></strong>
                                        </div>
                                        <div style={{textAlign: 'right'}}>
                                            <a className="calendario-mintc__btn" href={medico.url}>Prenota adesso</a>
                                        </div>
                                    </div>
                                    <div className="calendario-mintc__medico-spec calendario-mintc_prl8 TextSmall-18">{medico.specializzazione}</div>

                                    {

                                        medico.calendario ? Object.keys(medico.calendario).map((mese, index) => 
                                            <div key={index}>
                                                <div className="calendario-mintc__month calendario-mintc_prl8">{mese}</div> 
                                                <div className="calendario-mintc__days calendario-mintc_prl8">{medico.calendario[mese].join(' - ')}</div>
                                            </div>
                                        ) : <div className="calendario-mintc__tobedefined calendario-mintc_prl8">Calendario da definire</div>

                                    }
                                </div>

                            </div>

                        )
                    }
        
                </div>
        

                
            </div>
        );
    }  
}

export default CalendarioMINTC;
