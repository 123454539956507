import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import CalendarioMINTC from './CalendarioMINTC';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');
const calendarioMINTC = document.getElementById('calendarioMINTC');

// if(root) {
//     ReactDOM.render(<App />, root);
// }

if(calendarioMINTC) {
    ReactDOM.render(<CalendarioMINTC {...(calendarioMINTC.dataset)} />, calendarioMINTC);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
